<template>
  <el-dialog
    :visible.sync="dialog"
    :close-on-click-modal="false"
    :title="title + '地址'"
    append-to-body
    width="475px"
    @close="cancel"
    v-dialogDrag
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="88px"
      v-closeSelect
    >
      <el-form-item label="地区" class="form-row-float" prop="provinceCode">
        <div class="addres-wrapper">
          <el-select
            v-model="form.provinceCode"
            placeholder="请选择"
            @change="changeProvice"
            ref="refPro"
          >
            <el-option
              v-for="item in proviceList"
              :key="item.areaCode"
              :label="item.areaName"
              :value="item.areaCode"
              value-key="areaCode"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form.cityCode"
            placeholder="请选择"
            @change="changeCity"
            ref="refCity"
          >
            <el-option
              v-for="item in cityList"
              :key="item.areaCode"
              :label="item.areaName"
              :value="item.areaCode"
              value-key="areaCode"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form.countyCode"
            placeholder="请选择"
            @change="changeCountry"
            ref="refCountry"
          >
            <el-option
              v-for="item in countyList"
              :key="item.areaCode"
              :label="item.areaName"
              :value="item.areaCode"
              value-key="areaCode"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { addressMixin } from "@/utils/addressMixin";
import { post } from "@/api/index";
export default {
  mixins: [addressMixin],
  props: {
    sup_vue: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      title: "",
      form: {
        provinceCode: "440000000000",
        cityCode: "",
        countyCode: ""
      },
      rules: {
        provinceCode: [
          { required: true, trigger: "change", message: "请选择地址" }
        ]
      },
      type: "form"
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    initData(title, data) {
      this.loading = false;
      this.title = title;
      this.resetForm();
      if (data.provinceCode) {
        this.form = data;
      }
      console.log("formds", this.form);
      if (this.form.provinceCode) this.getCityList();
      if (this.form.cityCode) this.getCountryList();
      this.dialog = true;
    },
    handleChange(value) {
      console.log("handleChange", value, this.getCodeToText(null, value));
    },

    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          // if (!this.form.countyCode) {
          //   this.$message.warning("请选择所在区域");
          //   return;
          // }
          this.loading = true;

          var postData = new FormData();
          // if (this.form && this.form.countyCode) {
          postData.append("addr.cityCode", this.form.cityCode);
          postData.append("addr.countyCode", this.form.countyCode || "");
          postData.append("addr.provinceCode", this.form.provinceCode || "");
          // postData.append("area.address", this.form.address);
          // }
          post(this.$setApi("/cus/user/info"), postData)
            .then(res => {
              if (res.data) {
                this.dialogProblem = false;
                this.$message.success("操作成功");
                this.resetForm();
                this.sup_vue.getUserInfo();
              }
            })
            .catch(err => {
              console.log("失败", err);
              // this.$message.warning(err);
            });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.dialog = false;
      this.form = {
        provinceCode: "440000000000",
        cityCode: "",
        countyCode: ""
      };
    }
  }
};
</script>

<style scoped>
@import "~@/assets/css/form.css";
</style>
