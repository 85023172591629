<template>
  <div style="display: inline-block;">
    <el-dialog
      :visible.sync="dialog"
      :close-on-click-modal="false"
      :title="'绑定' + title"
      append-to-body
      width="475px"
      @close="cancel"
      v-dialogDrag
    >
      <div class="flex-center binging-box">
        请用{{ title }}扫一扫进行绑定
        <div class="binging-img">
          <img src="~images/home/ewm.png" alt="" />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { validPass, updateEmail } from "@/api/user";
// import { resetEmail } from "@/api/code";
export default {
  props: {},
  data() {
    return {
      loading: false,
      dialog: false,
      title: "微信 ",
      form: { phone: "", code: "" }
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    initData(title) {
      this.title = title;
      this.dialog = true;
    },
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.user = { email: this.form.email, password: this.form.pass };
          // updateEmail(this.form.code, this.user)
          //   .then(res => {
          //     this.loading = false;
          //     this.resetForm();
          //     this.$notify({
          //       title: "邮箱修改成功",
          //       type: "success",
          //       duration: 1500
          //     });
          //     store.dispatch("GetInfo").then(() => {});
          //   })
          //   .catch(err => {
          //     this.loading = false;
          //     console.log(err.response.data.message);
          //   });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped lang="stylus">
.binging-box
  flex-direction: column
  .binging-img
    overflow hidden
    width: 150px
    height: 150px
    margin-top: 16px
    img
      width: 100%
</style>
