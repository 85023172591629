import { get } from "@/api/index";
export const addressMixin = {
  data() {
    return {
      proviceList: [],
      cityList: [],
      countyList: [],
      type: ""
    };
  },
  created() {
    this.getProvinceList();
  },
  computed: {},
  methods: {
    // 获取省数据
    getProvinceList() {
      get(this.$setApi("/sys/area/provinces")).then(res => {
        console.log("/sys/area/provinces", res);
        this.proviceList = res.data;
      });
    },
    // 获取市数据
    getCityList() {
      get(this.$setApi("/sys/area/cites/" + this[this.type].provinceCode)).then(
        res => {
         
          this.cityList = res.data;
        }
      );
    },
    // 获取省数据
    getCountryList() {
      get(this.$setApi("/sys/area/counties/" + this[this.type].cityCode)).then(
        res => {
        
          this.countyList = res.data;
        }
      );
    },
    // 选择省
    changeProvice(val) {
      val;
      this[this.type].cityCode = "";
      this.cityList = [];
      this[this.type].countyCode = "";
      this.countyList = [];
      this.getCityList();
    },
    // 选择市
    changeCity(val) {
      val;
      this[this.type].countyCode = "";
      this.countyList = [];
      this.getCountryList();
    },
    // 选择区
    changeCountry(val) {
      val;
    }
  }
};
