<template>
  <div style="display: inline-block;">
    <el-dialog
      :visible.sync="dialog"
      :close-on-click-modal="false"
      :title="!bindEmail ? '绑定邮箱' : '修改邮箱'"
      append-to-body
      width="475px"
      @close="cancel"
      v-dialogDrag
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-width="88px"
      >
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="form.email"
            :readonly="bindEmail"
            :disabled="bindEmail"
          />
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input
            v-model="form.code"
            auto-complete="on"
            style="width:235px;margin-right: 15px;"
          />
          <el-button
            :loading="codeLoading"
            :disabled="isDisabled"
            size="small"
            type="primary"
            @click="sendCode"
            >{{ buttonName }}</el-button
          >
        </el-form-item>
        <el-form-item label="新邮箱" prop="newEmail" v-if="bindEmail">
          <el-input v-model="form.newEmail" />
        </el-form-item>
        <el-form-item label="验证码" prop="newCode" v-if="bindEmail">
          <el-input
            v-model="form.newCode"
            auto-complete="on"
            style="width:235px;margin-right: 15px;"
          />
          <el-button
            :loading="codeLoading1"
            :disabled="isDisabled1"
            type="primary"
            size="small"
            @click="sendNewCode"
            >{{ buttonName1 }}</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { validatEmail } from "@/utils/validate";
import { get, post, del1 } from "@/api/index";
import { sendEmail } from "@/api/user";
export default {
  props: {
    supVue: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      title: "修改邮箱",
      bindEmail: false,
      form: { email: "", code: "", newEmail: "", newCode: "" },
      // 旧邮箱
      codeLoading: false,
      isDisabled: false,
      time: 60,
      timer: null,
      // 新邮箱验证码
      codeLoading1: false,
      isDisabled1: false,
      time1: 60,
      timer1: null
    };
  },
  computed: {
    buttonName() {
      if (this.time == 60 || this.time == 0) return "发送验证码";
      else return `${this.time}秒后发送`;
    },
    buttonName1() {
      if (this.time1 == 60 || this.time1 == 0) return "发送验证码";
      else return `${this.time1}秒后发送`;
    },
    rules() {
      const validMail = (rule, value, callback) => {
        if (!value) {
          callback(new Error("邮箱不能为空"));
        } else if (validatEmail(value)) {
          callback();
        } else {
          callback(new Error("邮箱格式错误"));
        }
      };
      const validMail1 = (rule, value, callback) => {
        if (value === "" || value === null) {
          callback(new Error("新邮箱不能为空"));
        } else if (value === this.email) {
          callback(new Error("新邮箱不能与旧邮箱相同"));
        } else if (validatEmail(value)) {
          callback();
        } else {
          callback(new Error("邮箱格式错误"));
        }
      };
      if (!this.bindEmail) {
        return {
          email: [{ required: true, validator: validMail, trigger: "blur" }],
          code: [{ required: true, message: "验证码不能为空", trigger: "blur" }]
        };
      }
      return {
        email: [{ required: true, validator: validMail, trigger: "blur" }],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        newEmail: [{ required: true, validator: validMail1, trigger: "blur" }],
        newCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" }
        ]
      };
    }
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    sendCode() {
      if (validatEmail(this.form.email)) {
        const _this = this;
        if (this.bindEmail) {
          get(this.$setApi("/cus/bind/email/my/") + this.form.email).then(
            res => {
              console.log("phone/my", res, this.bindEmail);
              if (res.code == 200 && res.data) {
                this.sendEmailCode();
              } else this.$message.warning("该邮箱不是您的！！！");
            }
          );
        } else this.sendEmailCode();
      } else this.$message.warning("请输入正确邮箱!!!");
    },
    sendEmailCode() {
      this.codeLoading = true;
      sendEmail(this.form.email)
        .then(res => {
          this.$message({
            showClose: true,
            message: "发送成功，验证码有效期5分钟",
            type: "success"
          });
          _this.time--;
          this.codeLoading = false;
          this.isDisabled = true;
          this.timer = window.setInterval(() => {
            if (_this.time == 0) {
              window.clearInterval(_this.timer);
              _this.timer = null;
              _this.time = 60;
              _this.isDisabled = false;
            } else {
              _this.time--;
            }
          }, 1000);
        })
        .catch(err => {
          this.codeLoading = false;
        });
    },
    sendNewCode1() {
      if (this.form.email == this.form.newEmail) {
        this.$message.warning("新旧邮箱不能相同!!!");
        return;
      }
      if (validatEmail(this.form.newEmail)) {
        this.codeLoading1 = true;
        const _this = this;
        sendEmail(this.form.newEmail)
          .then(res => {
            this.$message({
              showClose: true,
              message: "发送成功，验证码有效期5分钟",
              type: "success"
            });
            _this.time1--;
            this.codeLoading1 = false;
            this.isDisabled1 = true;
            this.timer1 = window.setInterval(() => {
              if (_this.time1 == 0) {
                window.clearInterval(_this.timer1);
                _this.timer1 = null;
                _this.time1 = 60;
                _this.isDisabled1 = false;
              } else {
                _this.time1--;
              }
            }, 1000);
          })
          .catch(err => {
            this.codeLoading1 = false;
          });
      } else this.$message.warning("请输入正确邮箱!!!");
    },
    sendNewCode() {
      if (!this.form.email) {
        this.$message.warning("请输入邮箱!!!");
        return;
      }
      if (!this.form.code) {
        this.$message.warning("请输入当前邮箱验证码!!!");
        return;
      }
      if (!this.form.newEmail) {
        this.$message.warning("请输入新邮箱!!!");
        return;
      }
      if (this.form.email == this.form.newEmail) {
        this.$message.warning("新旧邮箱不能相同!!!");
        return;
      }
      if (!validatEmail(this.form.newEmail)) {
        this.$message.warning("请输入正确邮箱!!!");
        return;
      }

      get("/auth/email/valid/code", {
        email: this.form.email,
        code: this.form.code
      }).then(res => {
        if (res && res.data) {
          this.codeLoading1 = true;
          const _this = this;
          sendEmail(this.form.newEmail)
            .then(res => {
              this.$message({
                showClose: true,
                message: "发送成功，验证码有效期5分钟",
                type: "success"
              });
              _this.time1--;
              this.codeLoading1 = false;
              this.isDisabled1 = true;
              this.timer1 = window.setInterval(() => {
                if (_this.time1 == 0) {
                  window.clearInterval(_this.timer1);
                  _this.timer1 = null;
                  _this.time1 = 60;
                  _this.isDisabled1 = false;
                } else {
                  _this.time1--;
                }
              }, 1000);
            })
            .catch(err => {
              this.codeLoading1 = false;
            });
        } else this.$message.warning("当前邮箱或验证码不正确!!!");
      });
    },
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          let data = {
            account: this.form.email,
            code: this.form.code,
            state: "email"
          };
          if (this.bindEmail) {
            post(this.$setApi("/cus/bind/email/change"), {
              account: this.form.email,
              code: this.form.code,
              newAccount: this.form.newEmail,
              newCode: this.form.newCode,
              state: "email"
            })
              .then(res => {
                if (res && res.code && res.code == 200 && res.data) {
                  this.$message.success("邮箱修改成功");
                  this.supVue.getUserInfo();
                  this.resetForm();
                } else this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else this.onBingEmail(data);
        } else {
          return false;
        }
      });
    },
    onBingEmail(data) {
      post(this.$setApi("/cus/bind/email"), data)
        .then(res => {
          console.log("onBingEmail", res);
          if (res && res.code && res.code == 200 && res.data) {
            this.$message.success("邮箱绑定成功");
            this.supVue.getUserInfo();

            this.resetForm();
          } else this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.form = { email: "", code: "", newEmail: "", newCode: "" };
    }
  }
};
</script>

<style scoped lang="stylus">
.no-label
  >>>.el-form-item__content
    margin-left 15px !important
    display flex
    justify-content space-between

>>>.el-form-item__content
  display flex
</style>
