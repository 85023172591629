<template>
  <div style="display: inline-block;">
    <el-dialog
      :visible.sync="dialog"
      :close-on-click-modal="false"
      :title="!bindPhone ? '绑定手机' : '修改手机'"
      append-to-body
      width="475px"
      @close="cancel"
      v-dialogDrag
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-width="88px"
      >
        <el-form-item
          :label="!bindPhone ? '手机号码' : '当前手机号码'"
          prop="phone"
        >
          <el-input v-model="form.phone" maxlength="11" />
        </el-form-item>
        <el-form-item prop="code" label="验证码">
          <el-input
            v-model="form.code"
            auto-complete="on"
            style="width: 235px;margin-right: 15px;"
          />
          <el-button
            :loading="codeLoading"
            :disabled="isDisabled"
            size="small"
            type="primary"
            @click="sendCode"
            >{{ phoneTxt }}</el-button
          >
        </el-form-item>
        <el-form-item label="新手机号码" prop="newPhone" v-if="bindPhone">
          <el-input v-model="form.newPhone" maxlength="11" />
        </el-form-item>
        <el-form-item prop="newCode" label="验证码" v-if="bindPhone">
          <el-input
            v-model="form.newCode"
            auto-complete="on"
            style="width: 235px;margin-right: 15px;"
          />
          <el-button
            :loading="codeLoading1"
            :disabled="isDisabled1"
            size="small"
            type="primary"
            @click="sendCode1"
            >{{ phoneTxt1 }}</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { isvalidPhone } from "@/utils/validate";
// import { validPass, updateEmail } from "@/api/user";
// import { resetEmail } from "@/api/code";
import { get, post } from "@/api/index";

export default {
  props: {
    phone: {
      type: String,
      required: true
    },
    sup_vue: {
      type: Object
    }
  },
  data() {
    const validPhone = (rule, value, callback) => {
      if (value === "" || value === null) {
        callback(new Error("手机号码不能为空"));
      } else if (isvalidPhone(value)) {
        callback();
      } else {
        callback(new Error("手机格式错误"));
      }
    };
    const validNewPhone = (rule, value, callback) => {
      if (value === "" || value === null) {
        callback(new Error("新手机号码不能为空"));
      } else if (isvalidPhone(value)) {
        callback();
      } else {
        callback(new Error("手机格式错误"));
      }
    };
    return {
      loading: false,
      dialog: false,
      title: "修改号码",
      form: { phone: "", code: "",newPhone:'',newCode:'' },
      // 手机验证码
      codeLoading: false,
      isDisabled: false,
      time: 60,
      timer: null,
      rules: {
        phone: [{ required: true, validator: validPhone, trigger: "blur" }],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        newPhone: [{ required: true, validator: validNewPhone, trigger: "blur" }],
        newCode: [{ required: true, message: "验证码不能为空", trigger: "blur" }]
      },
      codeLoading1: false,
      isDisabled1: false,
      time1: 60,
      timer1: null,
      bindPhone: false
    };
  },
  computed: {
    phoneTxt() {
      if (this.time == 60 || this.time == 0) return "发送验证码";
      else return `${this.time}秒后发送`;
    },
    phoneTxt1() {
      if (this.time1 == 60 || this.time1 == 0) return "发送验证码";
      else return `${this.time1}秒后发送`;
    }
  },
  methods: {
    initData(phone, bindPhone) {
      this.resetForm();
      this.bindPhone = bindPhone;
      this.form.phone = phone || "";
      this.dialog = true;
    },
    cancel() {
      this.resetForm();
    },
    sendCode() {
      if (this.form.phone && isvalidPhone(this.form.phone)) {
        const _this = this;
        if (this.bindPhone) {
          get(this.$setApi("/cus/bind/phone/my/") + this.form.phone).then(
            res => {
              console.log("phone/my", res, this.bindPhone);
              if (res.code == 200 && res.data) {
                this.sendPhone();
              } else this.$message.warning("该手机号不是您的！！！");
            }
          );
        } else this.sendPhone();
      } else this.$message.warning("请输入正确手机格式!!!");
    },
    sendPhone() {
      this.codeLoading = true;

      post("/auth/phone/send/" + this.form.phone)
        .then(res => {
          this.$message({
            showClose: true,
            message: "发送成功，验证码有效期5分钟",
            type: "success"
          });
          _this.time--;
          this.codeLoading = false;
          this.isDisabled = true;
          this.timer = window.setInterval(() => {
            if (_this.time == 0) {
              window.clearInterval(_this.timer);
              _this.time = 60;
              _this.timer = null;
              _this.isDisabled = false;
            } else {
              _this.time--;
            }
          }, 1000);
        })
        .catch(err => {
          this.codeLoading = false;
          console.log(err.response.data.message);
        });
    },
    sendCode1() {
      if (!this.form.phone) {
        this.$message.warning("请输入当前手机号码!!!");
        return;
      }
      if (!this.form.code) {
        this.$message.warning("请输入当前手机验证码!!!");
        return;
      }
      if (!this.form.newPhone) {
        this.$message.warning("请输入新手机号码!!!");
        return;
      }
      if (!isvalidPhone(this.form.newPhone)) {
        this.$message.warning("请输入正确新手机号码!!!");
        return;
      }

      get("/auth/phone/valid/code", {
        phone: this.form.phone,
        code: this.form.code
      }).then(res => {
        if (res && res.data) {
          if (this.form.newPhone && isvalidPhone(this.form.newPhone)) {
            const _this = this;
            this.codeLoading1 = true;
            post("/auth/phone/send/" + this.form.newPhone)
              .then(res => {
                this.$message({
                  showClose: true,
                  message: "发送成功，验证码有效期5分钟",
                  type: "success"
                });
                _this.time1--;
                this.codeLoading1 = false;
                this.isDisabled1 = true;
                this.timer1 = window.setInterval(() => {
                  if (_this.time1 == 0) {
                    window.clearInterval(_this.timer1);
                    _this.time1 = 60;
                    _this.timer1 = null;
                    _this.isDisabled1 = false;
                  } else {
                    _this.time1--;
                  }
                }, 1000);
              })
              .catch(err => {
                this.codeLoading1 = false;
                // console.log(err.response.data.message);
              });
          } else this.$message.warning("请输入正确手机格式!!!");
        } else this.$message.warning("当前手机或验证码不正确!!!");
      });
    },
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          if (!this.bindPhone) {
            post(this.$setApi("/cus/bind/phone"), {
              account: this.form.phone,
              code: this.form.code,
              state: "phone"
            })
              .then(res => {
                if (res && res.code && res.code == 200 && res.data) {
                  this.loading = false;
                  this.dialogProblem = false;
                  this.$message.success("手机绑定成功");
                  this.resetForm();
                  this.sup_vue.getUserInfo();
                }
              })
              .catch(err => {
                this.loading = false;
                this.dialogProblem = false;
              });
          } else {
            post(this.$setApi("/cus/bind/phone/change"), {
              account: this.form.phone,
              code: this.form.code,
              newAccount: this.form.newPhone,
              newCode: this.form.newCode,
              state: "phone"
            })
              .then(res => {
                if (res && res.code && res.code == 200 && res.data) {
                  this.loading = false;
                  this.dialogProblem = false;
                  this.$message.success("手机修改成功");
                  this.resetForm();
                  this.sup_vue.getUserInfo();
                }
              })
              .catch(err => {
                this.loading = false;
                this.dialogProblem = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.dialog = false;
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
        this.time = 60;
        this.isDisabled = false;
      }
      this.form = { phone: "", code: "", newPhone: "", newCode: "" };
    }
  }
};
</script>

<style scoped lang="stylus">
.no-label
  >>>.el-form-item__content
    margin-left 15px !important
    display flex
    justify-content space-between

>>>.el-form-item__content
  display flex

>>>.el-form-item__label
  width 112px !important
</style>
